@media screen and (min-width: 1920px) {
  .serve-container .head-style p, .home-conitainer .second-conitainer .inspiredText p,.home-conitainer .third-conitainer .imgDescDiv p {
    font-size: 23px;
    line-height: 34px;
  }
  

  .home-conitainer .firsct-conitainer {
    /* background-size: unset; */
  }

}

@media screen and (min-width: 1439px) {
.home-conitainer .imgText-bg {
  background-image: url(../public/img/home/op-firstbg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover;
  width: 100%;
}
}

 @media screen and (min-width: 1920px) {
  /* .home-conitainer .second-conitainer .sec-row{
    padding-bottom: 60px;
  } */
  .home-conitainer .third-conitainer{
    position: relative;
    top: -1px;
}
 } 
 @media only screen and (max-width: 769px) and (min-width: 767px){
  .home-conitainer .second-conitainer, .home-conitainer .second-conitainer .bgImage {
    height: 950px;
  }
  .home-conitainer .third-conitainer {
    position: relative;
    top: -1px;
}
 } 
 @media screen and (max-width: 425px){
  .home-conitainer .bottleDiv {
    bottom: -175px!important;
  }
  .home-conitainer .bottleDiv img.img-fluid {
    right: -15px!important;
  }
  .home-conitainer .third-conitainer {
    position: relative;
    top: -1px;
}
/* .home-conitainer .bottleDiv {
  overflow: hidden;
} */
 }

 @media screen and (max-width: 374px){
  .home-conitainer .firsct-conitainer button.btn {
    font-size: 16px!important;
    padding: 4px 15px!important;
    margin: 15px 0px !important;
    line-height: 25px;
 }
}
@media screen and (max-width: 1440.5px) {
  .home-conitainer .imgText-bg h1 {
    font-size: 43px;
  }

  .home-conitainer .imgText-bg p {
    font-size: 21px;
    line-height: 40px;
  }
}
@media (max-width: 991.5px) {
  .story-image3 {
    left: -13%;
  }
}

@media screen and (max-width: 1199.5px) {
  .home-conitainer .third-conitainer .imgDescDiv .head-style p br {
    display: none;
  }

  .home-conitainer .third-conitainer .whiskeyCocktail-bg {
    /* height: 725px; */
    height: 100%;
  }

  .home-conitainer .third-conitainer h2 {
    font-size: 90px;
    width: fit-content;
    /* margin: auto; */
    /* padding-top: 30px; */
  }

  .home-conitainer .third-conitainer .imgDescDiv {
    margin: 60px auto 0;
  }

  .home-conitainer div.container {
    padding: 0 20px;
  }

  .storyContainer1 {
    padding-left: 0;
  }

  .story-image2 {
    margin-left: 0;
  }

  .story-image4 {
    max-width: 281px;
    width: 100%;
    max-height: 182px;
    height: 100%;
    display: block;
    left: 59%;
    bottom: 9.4%;
    margin-bottom: 7px;
  }

  .dFlex {
    display: flex;
    flex-direction: column;
  }

  .firstChild,
  .secondChild,
  .thirdChild,
  .forthChild {
    width: 100%;
  }

  .firstFlex .firstChild {
    flex-direction: column-reverse;
  }

  .secondFlex .firstChild {
    order: 2;
  }

  .secondFlex .secondChild {
    order: 1;
  }

  .secondFlex .thirdChild {
    order: 3;
  }

  .secondFlex .forthChild {
    order: 4;
  }

  .secondFlex .fifthChild {
    order: 5;
  }

  .story-image5 {
    width: 100;
    max-width: 800px !important;
    width: 100%;
    display: block;
    margin: auto;
  }
}

@media screen and (max-width: 1170px) {
  .parrHeader .container {
    padding: 0 30px;
  }
  .serve-child-conitainer .img-div .img-desc {
    justify-content: space-between;
    padding: 70px 20px 0;
  }
  
  .serve-child-conitainer .img-div .img-desc div {
    max-width: 340px;
  }

  .home-conitainer .sliderContain {
    max-width: 810px;
  }

  .serve-container .learn-more-btn {
    font-size: 13.5px;
  }

  .serve-child-conitainer .head-style h1,
  .imgAndDesc h1 {
    font-size: 43px;
    text-align: start;
  }

  .serve-child-conitainer .head-style {
    text-align: start;
    /* margin: 0; */
  }

  .home-conitainer .third-conitainer .imgDescDiv .head-style h2 {
    font-size: 36px !important;
    margin-bottom: 10px;
  }

  .home-conitainer .third-conitainer .imgDescDiv p {
    line-height: 23.75px;
    font-size: 20px;
    margin: 15px 0;
  }

  .serve-child-conitainer .head-style p {
    /* margin-bottom: 200px; */
  }

  .serve-child-conitainer .child-bg {
    /* padding-bottom: 295px; */
  }
}

@media screen and (max-width: 1025px) {
  .brand-img-div .age-img {
    max-width: 250px;
  }
}

@media screen and (min-width: 980px) {
  .bottel-container .imgAndDesc {
    width: 950px;
  }
}

@media screen and (max-width: 991.5px) {
  .serve-container .head-style h2 {
    font-size: 36px;
  }

  .story-container .mt120 {
    margin-top: 74px;
  }

  .serve-container .head-style p {
    font-size: 18px;
    width: 80%;
    line-height: 22px;
    margin-bottom: 40px;
    margin-top: 30px;
  }

  .serve-container .head-style {
    max-width: 100%;
    padding: 40px 40px 20px;
  }

  .serve-container .the-serve-image.img-fluid {
    width: 100%;
  }

  .hiddenText {
    display: none;
  }
  .dropdown.whiskies-dropdown{
    min-height: unset;
  }
  .overflowHidden {
    overflow: hidden;
  }

  .home-conitainer .imgText-bg p {
    font-size: 20px;
    line-height: 24px;
    padding-top: 10px;
  }

  .home-conitainer .imgText-bg img.img-fluid {
    width: 320px;
  }

  /* .home-conitainer .second-conitainer,
  .home-conitainer .second-conitainer .bgImage {
    height: 665px;
  } */
  .home-conitainer .second-conitainer .bgImage {
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 97%);
  }

  /* .home-conitainer .third-conitainer h2 {
    font-size: 90px;
    width: fit-content;
    margin: auto;
  } */
  /* .home-conitainer .third-conitainer .whiskeyCocktail-bg {
    height: 670px;
  } */
  /* .home-conitainer button.btn {
    font-size: 14px;
    padding: 12px 30px;
  } */
  .home-conitainer .third-conitainer .imgDescDiv {
    padding: 0px 12px 15px;
  }

  /* .home-conitainer .third-conitainer h2 {
    font-size: 50px;
  } */

  /* .home-conitainer .second-conitainer,
  .home-conitainer .second-conitainer .bgImage,
  .home-conitainer .third-conitainer .whiskeyCocktail-bg {
    height: 660px;
  } */
  .imgDescDiv img.img-fluid {
    width: 100%;
  }

  /* .fourth-conitainer .blendModeDiv {
    padding-top: 200px;
  } */
  /* .sliderContain .brandImg img {
    width: 250px;
  } */

  .home-conitainer .brandAge img {
    width: 130px;
  }

  .home-conitainer .third-conitainer .imgDescDiv .head-style h2 {
    margin: 30px 0;
  }

  /* .home-conitainer .sliderContain {
    max-width: 580px;
  }
  .home-conitainer .third-conitainer {
    height: 610px;
  } */
  .home-conitainer .second-conitainer .inspiredText h2 {
    font-size: 32px;
  }

  .home-conitainer .second-conitainer .inspiredText p {
    font-size: 16px;
  }

  .near-you-conitainer select.form-select {
    width: 250px;
    font-size: 14px;
  }

  .brand-img-div .age-img {
    max-width: 190px;
  }

  .home-conitainer .sliderContain {
    max-width: 640px;
  }

  .bottel-container .bottel-img img.deksTopImg {
    width: 345px;
    top: 225px;
  }

  .bottel-container .imgAndDesc {
    padding: 100px 0px 50px;
  }

  .imgAndDesc .descText p {
    opacity: 0.8;
  }

  .fourth-conitainer .blendModeDiv .slick-next {
    top: 43% !important;
  }

  .imgAndDesc .headingText p,
  .imgAndDesc .descText p {
    font-size: 14px;
  }

  .story-container h2.h2-heading {
    font-size: 53px;
  }
  .story-container h2.h2-heading.fontLarge {
    font-size: 59px;
  }

  h2.h2-heading {
    font-size: 56px;
    padding-top: 56px;
    /* text-align: start !important; */
  }

  h3.h2-heading {
    font-size: 40px;
    line-height: 115%;
  }

  .storyContainer1,
  .drivenDiv .fs30,
  .storyContainer2,
  .storyContainer1.fs25,
  .drivenDiv p {
    font-size: 25px;
    /* margin-bottom: 10px; */
    line-height: 30px;
  }

  .firstFlex .firstChild {
    display: none;
  }

  .firstFlex .secondChild .story-image1.d-none {
    display: block !important;
    padding-bottom: 74px;
    width: 100vw;
    /* position: relative; */
    width: 100vw;
    /* max-width: 100vw; */
    /* left: -13%; */
  }

  .story-container .mt120.firstChild {
    margin-top: 21px;
  }

  /* .story-container .mt70 {
    margin-top: 25px;
  } */

  .heading_middle_text {
    font-size: 53px;
    text-align: start;
    padding: 54px 0;
  }

  .child-bg.container {
    max-width: 93%;
  }

  .serve-child-conitainer .img-div .img-desc {
    padding: 50px 50px 0;
  }

  .serve-child-conitainer .img-div .img-desc {
    /* max-height: 357px; */
    max-height: 460px;
    z-index: unset;
  }

  .child-bg.container .img-div img {
    height: 506px;
  }

  .parrHeader .nav-link,
  .parrHeader .dropdown-item {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: rgb(255, 255, 255);
    margin: 0 0 24px 24px !important;
    font-family: "Century Gothic W05 Regular", sans-serif;
  }

  .parrHeader .dropdown-menu {
    width: auto;
  }

  .parrHeader .dropdown-menu {
    background-color: transparent;
    border: 0;
  }

  .parrHeader .dropdown-menu .nav-link.nav-item {
    padding: 0 0 0 20px !important;
    border-left: 2px solid #ffffff;
  }

  /* .near-you-conitainer .nearYou {
    flex-direction: column;
    align-items: start !important;
  } */

  .near-you-conitainer,
  .order-conitainer {
    padding: 20px 0;
  }

  .mt-12 {
    margin-top: 12px !important;
  }

  .footerText {
    padding-left: 50px;
  }

  .story-image3 {
    /* position: relative; */
    max-width: 90vw !important;
    width: 90vw;
  }

  .storyContainer1.d-none {
    display: block !important;
    font-size: 21px;
    line-height: 27px;
    padding-bottom: 40px;
  }

  .secondFlex .forthChild {
    display: none;
  }

  .story-image4 {
    left: 0%;
    margin-left: 0;
    margin-right: 0;
    max-width: 100vw;
    right: 0%;
    width: 90vw;
    display: block;
    max-height: 100%;
    height: auto;
  }

  .drivenDiv.text-center {
    text-align: start !important;
    max-width: 100%;
    width: 100%;
  }

  .drivenDiv.text-center p {
    padding-top: 0;
  }

  .secondFlex .fifthChild {
    margin-top: 0 !important;
  }

  .rightNav {
    flex-direction: row !important;
  }

  .parrHeader p {
    /* padding-top: 33px; */
    font-family: "Century Gothic";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: 24px;
    max-width: 85%;
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 950.5px) {
  .whereDiv {
    display: flex;
    flex-direction: column;
  }

  .whereDiv .find-section,
  .whereDiv .shop-online {
    width: 100%;
  }

  .regular.slider {
    max-width: 309px;
    width: 100%;
    margin: auto;
  }

  .regular.slider div h4 {
    font-weight: 500 !important;
    margin: 10px 0 5px;
  }

  .margin50 {
    margin-top: 40px !important;
  }

  .serve-child-conitainer .container-slider {
    display: none;
  }
}

@media screen and (max-width: 890.5px) {
  .nearYou,
  .order-conitainer .align-items-center.row {
    display: block;
    margin: 0 72px;
  }

  .nearYou .text-start,
  .order-conitainer h2 {
    margin-bottom: 40px;
  }

  .nearYouText,
  .nearYou .text-start {
    width: 100%;
  }
}

@media screen and (max-width: 867.5px) {
  /* .input.zipCode{
    position: absolute;
    bottom: 53px;
    left: 0;
  } */
  .nearYou {
    justify-content: end !important;
  }
}

@media screen and (max-width: 800px) {
  .order-conitainer button.btn {
    width: 200px;
    height: 46px;
  }

 .firstDiv img.dash-img {
    width: auto!important;
}
}

@media screen and (max-width: 761.5px) {
  .nearYouText {
    display: block;
  }

  .nearYou .text-start,
  .input.zipCode,
  .input select,
  .order-conitainer h2,
  .order-conitainer button.btn {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 766.5px) {
  .sliderContain .brandImg img {
    width: 200px;
  }
  .home-conitainer .imgText-bg h1{
    font-size: 38px;
  }
}

@media screen and (max-width: 769px) {
  .imgDescTextDiv.col-md-6 {
    max-width: 550px !important;
    margin: auto;
  }
  p.additional-text{
    text-align: center;
    color: #212529;
    font-size: 14px!important;
    font-style: normal;
    line-height: 18px!important;
    font-weight: 400;
    margin-left: 6px;
  }
  .additional-text{
    max-width: 325px;
    width: 100%;
    text-align: center;
    margin: 0 auto;

  }
  .percent-text {
    padding-top: 20px;
    padding-bottom: 10px;
}
}

@media screen and (max-width: 767.5px) {
  .serve-child-conitainer .img-div .img-desc {
    display: block;
    max-height: 500px;
    padding: 50px 20px 0;
  }
  .serve-child-conitainer .child-bg {
    padding-bottom: 398px;
  }
  p.additional-text{
    text-align: center;
    color: #212529;
    font-size: 14px!important;
    font-style: normal;
    line-height: 18px!important;
    font-weight: 400;
  }
  
}
@media screen and (max-width: 768.5px) {
  .col-md-6 {
    width: 100% !important;
  }

  .storyContainer1.d-none {
    padding-bottom: 0;
  }

  .home-conitainer .sliderContain {
    max-width: 100%;
    flex-direction: column;
  }

  .home-conitainer button.btn.serveBtn {
    font-size: 13.5px;
    padding: 10.5px 29.5px;
    line-height: 20.5px;
  }

  .brandImg .dDekstop,
  .colNone {
    display: none !important;
  }

  .brandImg .img-fluid.dMobaile.d-none {
    display: inline-block !important;
  }

  .navbar-toggler .icon-bar {
    margin-top: 5px;
    width: 30px;
  }

  .home-conitainer .imgText-bg p {
    font-size: 16px;
    /* font-style: italic; */
  }

  .forDekstop {
    display: none;
  }

  .forMobile {
    display: block !important;
  }

  .home-conitainer .second-conitainer .inspiredImg {
    /* width: 240px; */
    margin: auto;
  }

  /* .home-conitainer .second-conitainer,
  .home-conitainer .second-conitainer .bgImage {
    height: 470px;
  }
  .home-conitainer .third-conitainer .whiskeyCocktail-bg {
    height: 1111px;
  }
  .third-conitainer .whiskeyCocktail-bg {
    height: 1150px;
  }
  .home-conitainer .third-conitainer {
    height: 1025px;
  } */
  .home-conitainer .second-conitainer .inspiredText h2 {
    margin: 0 auto 15px;
    /* font-size: 32px; */
  }

  /* .home-conitainer .second-conitainer,
  .home-conitainer .second-conitainer .bgImage {
    height: 675px;
  } */

  .whiskeyCocktail-bg .container {
    max-width: 100%;
  }

   
  /* .imgDescDiv.container .head-style {
    max-width: 480px;
    margin: auto;
  } */
  /* .imgDescDiv img.img-fluid {
    width: 480px;
  } */
  /* .home-conitainer .third-conitainer .imgDescDiv {
    padding: 20px 15px;
  } */
  .fourth-conitainer .blendModeDiv {
    /* padding-top: 300px; */
    padding-bottom: 100px;
  }

  .slick-arrow {
    background-color: #000000 !important;
    height: 30px !important;
    width: 30px !important;
    background-size: 50% !important;
  }

  .sliderContain .brandImg img {
    bottom: 0;
    margin-bottom: 50px;
  }

  /* .home-conitainer .imgText-bg h1, */
  /* .home-conitainer .second-conitainer .inspiredText h2 {
    font-size: 25px;
  } */
  .home-conitainer .second-conitainer .inspiredText h2 {
    /* max-width: 100%; */
    margin: 0;
  }

  .home-conitainer .brandAge img {
    /* width: 100px; */
    margin: 0 20px !important;
  }

  .home-conitainer .imgText-bg {
    background-image: url(../public/img/mobile-banner-bg-image-updated.png);
  }

  .home-conitainer .imgText-bg .descText {
    margin-top: 20px;
  }

  .home-conitainer .second-conitainer .inspiredText p {
    /* font-size: 22px;
    line-height: 25px; */
    margin-top: 15px;
  }

  .near-you-conitainer select.form-select {
    width: 230px;
  }

  .shop-online {
    margin-top: 50px !important;
  }

  .shop-online .copy.p-3 {
    display: none;
  }

  .bottel-container .bottel-bg img {
    height: 305px;
  }

  .bottel-container .bottel-img img.deksTopImg {
    display: none;
  }

  .bottel-container .bottel-img img.mobileImg {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 160px;
  }

  .bottel-container .brandAge {
    justify-content: center;
    height: auto;
    margin-top: 65px;
  }

  /* .brand-img-div .age-img {
    max-width: 320px;
  } */

  .brand-img-div .buttons {
    max-width: 100%;
    width: 100%;
    justify-content: space-around;
    display: flex !important;
  }

  .bottel-container .imgAndDesc {
    padding: 50px 12px;
  }

  .imgAndDesc h1 {
    margin: 50px 0 30px 0;
  }

  .imgAndDesc p {
    font-size: 22px;
    line-height: 33px;
  }

  .brand-img-div h3 {
    margin-top: 115px;
    font-size: 20px;
  }

  .story-image4 {
    position: inherit;
    margin-bottom: 20px;
  }

  .drivenDiv.w-100 {
    max-width: 100%;
  }

  .slick-arrow {
    height: 35px !important;
    width: 35px !important;
  }

  /* .serve-child-conitainer .img-div .border-div {
    display: none;
  } */

  .child-bg.container .img-div img {
    /* height: 600px; */
     /*width: 100%; */
  }
  .serve-child-conitainer .img-div .border-div {
    /* height: 420px;
    max-width: 84%;
    z-index: 100;
    position: absolute;
    width: 84%;
    bottom: auto;
    margin: auto;
    left: 0;
    right: 0;
    top: 30%; */
}


  .serve-child-conitainer .img-div .img-desc {
    /* max-height: unset;
    height: unset;
    padding: 50px 25px;
    position: unset; */
  }

  /* .serve-child-conitainer .head-style p {
    margin-bottom: 50px;
  } */

  .serve-child-conitainer .img-div .img-desc div {
    max-width: 100%;
  }

  section.error-page {
    margin: 50px 0 70px;
  }

  section.error-page h1 {
    margin-bottom: 15px;
    font-size: 35px;
  }

  section.error-page h1 span {
    font-size: 100px;
  }

  section.error-page h2 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 25px;
  }

  section.error-page p {
    font-size: 15px;
  }

  .home-conitainer .third-conitainer h2 {
    font-size: 40px;
  }

  .fourth-conitainer .blendModeDiv .slick-next {
    top: 39% !important;
  }
}

@media screen and (max-width: 550.5px) {
  .newsLetter .touchForm .form-control,
  .newsLetter .touchForm select {
    font-size: 13px;
  }
  .serve-child-conitainer .img-div .border-div {
    /* height: 34%; */
    max-width: 802px;
    z-index: 100;
    position: absolute;
    /* border-width: 50px;
    border-style: solid;
    border-color: transparent; */
    width: 84%;
    left: 0;
    right: 0;
    margin: auto;
    top: 8%;
  }

  .newsLetter .touchForm .form-check {
    font-size: 10px;
    color: #000000;
    margin-bottom: 7px;
  }

  .newsLetter .touchForm .form-check label {
    margin-left: 5px;
  }

  /* .newsLetter .touchForm .form-check input {
    margin-top: 3%;
  } */

  .newsLetter .touchForm button {
    max-width: 150px;
    padding: 1px 6px;
    height: auto;
  }

  .newsLetter .touchForm .smallText {
    font-size: 12px;
    line-height: 1;
    color: #000000;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .near-you-conitainer .zipCode input,
  .near-you-conitainer button.btn {
    width: 230px;
  }
}

@media screen and (max-width: 575.5px) {
  .home-conitainer .imgText-bg img.img-fluid.m {
    display: block;
    width: 150px;
    float: right;
  }
  .child-bg.container .img-div img{
    height: 430px;
    width: 92%;
    margin: auto;
  }

  .home-conitainer .imgText-bg img.img-fluid.d {
    display: none;
  }

  .home-conitainer .imgText-bg h1 {
    font-size: 33px;
  }

  .home-conitainer .second-conitainer .inspiredText h2 {
    max-width: 250px;
    /* font-size: 25px; */
  }

  /* .home-conitainer .imgText-bg p {
    line-height: normal;
    margin-bottom: 0;
    padding-top: 20px;
    max-width: 250px;
  } */
  .home-conitainer .imgText-bg p {
    margin-bottom: 0;
    max-width: 250px;
    padding-top: 20px;
    font-weight: 600;
  }
  .home-conitainer .second-conitainer .inspiredText p {
    width: 85%;
    margin: 20px auto;
  }

  /* .home-conitainer .third-conitainer .whiskeyCocktail-bg {
    height: 850px;
  }
  .home-conitainer .third-conitainer {
    height: 1050px;
  } */
  .slick-prev {
    left: 10px !important;
  }

  .slick-next {
    right: 10px !important;
  }

  .srtoryrow6text img {
    max-width: 145px;
  }

  .serve-container .head-style {
    max-width: 100%;
    padding: 20px 10px 20px;
  }

  .serve-container .head-style p,
  .child-bg.container {
    width: 100%;
    max-width: 100%;
  }

  .home-conitainer .firsct-conitainer button.btn {
    font-size: 16px;
    padding: 8px 24px;
    border-radius: 57.1691px;
    margin: 17px 0px;
  }
  .serve-child-conitainer .child-bg {
    padding-bottom: 0;
  }
  .serve-child-conitainer .img-div .img-desc {
    /* height: 660px;
    max-height: 1000px; */
    position: sticky;
    /* z-index: 99; */
    z-index: unset;
    height: 100%;
    max-height: 100%;
    padding-bottom: 20px;
}
}

@media screen and (max-width: 575.5px) {
  .home-conitainer .bottleDiv {
    bottom: -149px;
    position: absolute;
    width: 100%;
  }
  .nearYou,
  .order-conitainer .align-items-center.row {
    margin: 0 30px;
  }

  .home-conitainer .bottleDiv img.img-fluid {
    bottom: 0px;
    max-width: 200px;
    position: relative;
    z-index: 1;
    right: -15px;
    float: right;
  }

  .home-conitainer .imgText-bg {
    padding-bottom: 90px;
  }

  .home-conitainer .second-conitainer .bgImage {
    padding-top: 110px;
  }
  .serve-child-conitainer .img-div .img-desc li{
    /* list-style-type: none; */
    list-style-image: unset;
  }
  .serve-child-conitainer .img-div .border-div, .serve-child-conitainer .img-div .img-desc{
    width: 92%;
    z-index: 0;
  }
}

@media screen and (max-width: 426px) {
  .imgAndDesc.container .col-lg-7.col-md-6.col-12 {
    padding: 0;
    overflow: hidden;
  }

  .imgAndDesc .wbottle img {
    width: 550px;
    position: relative;
    left: -45px;
  }
  .child-bg.container .img-div img{
    height: 380px;
  }
}

/* @media screen and (max-width: 376px) {
  .imgAndDesc .wbottle img {
    left: -40px;
  }
} */


@media screen and (max-width: 420px) {
  .footerText {
    padding-left: 20px;
  }

  .imgAndDesc .wbottle img {
    width: 550px;
    position: relative;
    /* left: -120px; */
  }
  .serve-child-conitainer .child-bg {
    padding-bottom: 0;
  }
}





@media only screen and (max-width: 391px) and (min-width: 376px)  {
  .wbottle.yr18-bottle-img img{
    left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 426px) {
  .imgAndDesc .wbottle img {
    max-width: 515px;
  }
  .home-conitainer .third-conitainer .imgDescDiv {
    margin: 20px auto 0;
}
}
@media screen and (max-width: 376px) {
  .imgAndDesc .wbottle img {
    max-width: 450px;
    left: -40px;
  }
}
@media screen and (max-width: 325px) {
  .imgAndDesc .wbottle img {
    max-width: 400px;
  }
} 
@media screen and (min-width: 768px) {
  .descMainDiv .descText.desc_content{
    width: 310px;
  }
}





@media screen and (max-width: 334.5px) {
  .descMainDiv .buttons button.bg-btn-danger {
    margin-left: 0;
    margin-top: 7px;
  }
  .home-conitainer .imgText-bg p {
    max-width: 200px;
  }
  .home-conitainer .bottleDiv img.img-fluid {
    bottom: 0px;
    max-width: 200px;
    position: relative;
    z-index: 1;
    right: -15px;
    float: right;
  }

  .home-conitainer .bottleDiv {
    bottom: -149px;
    position: absolute;
    width: 100%;
  }
  .serve-child-conitainer .child-bg {
    padding-bottom: 0;
  }
  .child-bg.container .img-div img{
    height: 330px;
  }
}

@media screen and (max-width: 541.5px) {

  .old-par-bottle{
    padding: 0px!important;
  }
  .dekstopBtn,
  .forDekstop50 {
    display: none;
  }

  .mobileBtn,
  .forMobile50.d-none {
    display: block !important;
  }

  .mobileBtn,
  .forMobile50.d-none {
    margin-left: 40%;
    z-index: 1;
  }

  /* .home-conitainer .imgText-bg {
    padding-bottom: 20px;
  } */
  /* .home-conitainer .imgText-bg h1, */
  /* .home-conitainer .second-conitainer .inspiredText h2, */
  /* .home-conitainer .imgText-bg p, */
  .home-conitainer .second-conitainer .inspiredText p

  /* .home-conitainer .third-conitainer .imgDescDiv p  */ {
    font-size: 16px;
  }

  .home-conitainer .second-conitainer .inspiredText p {
    width: 100%;
    line-height: 27px;
    font-size: 18px;
  }

  .home-conitainer .second-conitainer .inspiredText button.btn {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .home-conitainer .second-conitainer .inspiredImg {
    height: 234px;
    margin: 0 10px 0 0;
    width: auto;
    position: absolute;
    left: -40px;
  }

  .inspiredText.forMobile {
    padding-left: 40%;
    z-index: 3;
  }

  .bgImage .d-flex.align-items-center.col-md-5.col-sm-12 {
    margin-top: 10%;
  }

  .home-conitainer .imgText-bg .descText {
    margin-top: 0;
  }

  .home-conitainer .second-conitainer,
  .home-conitainer .second-conitainer .bgImage {
    height: 650px;
  }

  .home-conitainer .third-conitainer h2,
  .home-conitainer .third-conitainer .imgDescDiv .head-style h2 {
    font-size: 40px;
    /* padding-top: 10px; */
    /* margin: 0; */
  }

  .home-conitainer .sliderContain .brnadDesc {
    display: block;
  }

  .home-conitainer .sliderContain {
    justify-content: space-around;
  }

  /* .home-conitainer .third-conitainer .imgDescDiv {
    margin: 10px auto;
  } */

  /* .home-conitainer .third-conitainer {
    height: 670px;
  } */
  /* .imgDescDiv img.img-fluid {
    height: 337px;
  } */
  

  .where-to-buy-Headline h1 {
    font-size: 45px;
  }

  .imgAndDesc h1 {
    margin: 40px 0 20px 0;
    max-width: 100%;
  }

  /* .imgAndDesc p {
    font-size: 16px;
    line-height: 25px;
  }  */

  .buttons button.bg-btn-danger {
    margin-left: 10px;
  }

  .buttons button {
    font-size: 12.5px;
    width: 150px;
    /* width: 100%; */
  }

  /* .brandAge .age-img img {
    width: 120px;
  } */

  /* h2.h2-heading {
    font-size: 35px;
    padding-top: 10px;
  } */

  /* .story-container .mt120, */
  .story-container .mt70,
  .golden-line {
    margin-top: 10px;
  }

  /* .heading_middle_text {
    padding: 13px 0;
  } */

  /* h3.h2-heading {
    font-size: 25px;
  } */

  section.error-page {
    margin: 25px 0 35px;
  }

  section.error-page h1 {
    margin-bottom: 7px;
    font-size: 20px;
  }

  section.error-page h1 span {
    font-size: 50px;
  }

  section.error-page h2 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 25px;
  }

  .order-conitainer button.btn {
    width: 142px;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner .dg_footer_row_0 a {
    text-align: start !important;
  }

  .home-conitainer button.btn {
    font-size: 16px;
  }

  .brand-img-div h3 {
    margin-top: 80px;
  }

  .home-conitainer .brandAge img {
    width: 106px;
  }

  .brandAge.d-flex.align-items-center.justify-content-between {
    justify-content: center !important;
  }
  .near-you-conitainer select.form-select {
    font-size: 12px;
  }
}


@media screen and (max-width: 856.5px) {
  .serve-child-conitainer .img-div div h1 {
    font-size: 32px;
  }
  .serve-child-conitainer .img-div .border-div, .serve-child-conitainer .img-div .img-desc{
    width: 94%;
  }

}

@media screen and (max-width: 426px) {
  .additional-text{
    max-width: 325px;
    width: 100%;
    text-align: center;
    margin: 0 auto;

  }
  .buttons.mt-lg-5.mt-4 {
    text-align: center;
}
p.additional-text {
  margin: 0 auto;
}
  }
  @media screen and (max-width: 749px) {
    .buttons.mt-lg-5.mt-4 {
      text-align: center;
  }
  p.additional-text {
    margin: 0 auto;
  }
  }