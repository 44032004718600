@font-face {
  font-family: "Conduit ITC W05 Extra Light";
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=14&fcId=4f3212db-73a8-4455-9b27-0dd8bda8d50b&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff2"),
    url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=3&fcId=4f3212db-73a8-4455-9b27-0dd8bda8d50b&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff");
}

@font-face {
  font-family: "Conduit ITC W05 Regular";
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=14&fcId=b12ba57a-506e-47de-9095-72a894512f23&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff2"),
    url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=3&fcId=b12ba57a-506e-47de-9095-72a894512f23&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff");
}

@font-face {
  font-family: "Conduit ITC W05 Extra Bold";
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=14&fcId=8fbde607-aecb-4630-a0fc-f488a8ced80b&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff2"),
    url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=3&fcId=8fbde607-aecb-4630-a0fc-f488a8ced80b&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff");
}

@font-face {
  font-family: "Conduit ITC W05 Black";
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=14&fcId=f3bfdb10-1e13-4475-95a6-c0dfb39b8140&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff2"),
    url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=3&fcId=f3bfdb10-1e13-4475-95a6-c0dfb39b8140&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff");
}

@font-face {
  font-family: "Basic Commercial LT W05 Light";
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=14&fcId=88525941-9be8-4e65-af48-c58de82e5f14&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff2"),
    url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=3&fcId=88525941-9be8-4e65-af48-c58de82e5f14&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff");
}

@font-face {
  font-family: "Basic Commercial LT W05 Lt It";
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=14&fcId=8aa109c6-3272-4593-bf8e-6dcbf216dec3&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff2"),
    url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=3&fcId=8aa109c6-3272-4593-bf8e-6dcbf216dec3&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff");
}

@font-face {
  font-family: "Basic Commercial LT W05 Roman";
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=14&fcId=cd07d9d3-e8b7-46a4-8edb-0601adc4cae2&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff2"),
    url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=3&fcId=cd07d9d3-e8b7-46a4-8edb-0601adc4cae2&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff");
}

@font-face {
  font-family: "Basic Commercial LT W05 Italic";
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=14&fcId=fbf0ff93-a3c7-478b-893b-2c3afe5f6067&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff2"),
    url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484157fa690e8185d931f6e1198a37f621a19a4730a2bf1cf9457677b9ec68b393b7288f25ada2a4b66c17fd0ce6eb01e2d1d41e34c918443347d71e58cdf48cd8821e13ed3a3f3fd26df4b1685098e09f0b9dc23a77b9f3cb18d5c403464defd278bcc6885e204f10f35d2309f8474fa192e7c5c69305841036e0af7d486b4902cf7e99b32c13e595622820a01fc08b7b7eb078ada2adc53&fctypeId=3&fcId=fbf0ff93-a3c7-478b-893b-2c3afe5f6067&languages=en,fi,fr,de,pt,es&ot=false&projectId=0ef99124-4512-4938-b42b-738279ba570f") format("woff");
}

* {
  text-decoration: none;
  margin: 0;
  padding: 0;
}

/* common css for all pages */
h1,
h2,
h3,
h4,
h4,
h6 {
  font-family: "Conduit ITC W05 Regular";
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold !important;
}

p {
  font-family: "Conduit ITC W05 Regular";
  /* font-style: italic; */
  font-weight: normal;
}

button.btn {
  background-color: rgb(216, 62, 53) !important;
  border-color: #d83e35 !important;
  font-family: "Basic Commercial LT W05 Roman";
  font-weight: bold;
  border-radius: 30px;
}

.awards h4 {
  font-size: 21px;
  text-align: center;
}

.forMobile,
.mobileBtn {
  display: none !important;
}

.maxw-100 {
  max-width: 100%;
  width: 100%;
}

/* Header style */
.main-div {
  position: relative;
  transition: transform 400ms ease-in-out 0s;
}

.navbar-toggler {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.navbar-toggler .icon-bar {
  width: 40px;
  height: 2px;
  background-color: #ffffff;
  display: block;
  margin-top: 8px;
}

.headerwrapper {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: auto;
  background-color: black;
  position: static;
  top: 0px;
  left: 0px;
  z-index: 20;
  width: 100%;
  flex: 0 0 100%;
  min-height: 80px;
  z-index: 99999;
}
header.header-wrapper.headerwrapper {
  position: sticky;
}

.parrHeader {
  height: 80px;
  background-color: #000000;
}

.parrHeader .closeBtn {
  color: transparent;
  opacity: 1;
  z-index: 999999999;
  position: relative;
  text-shadow: 0 0 0 #ffffff;
  font-size: 18px;
}

.parrHeader .container {
  max-width: 1162px;
}

.parrHeader .nav-link,
.parrHeader .dropdown-item {
  height: 100%;
  align-items: center;
  color: #ffffff !important;
  margin: 0px 12px;
  display: flex;
  font-style: italic;
  font-weight: bold;
  font-size: 16px;
  line-height: 13px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  padding: 8px 0 !important;
  cursor: pointer;
  font-family: "Basic Commercial LT W05 Roman";
  background-color: transparent !important;
}

.parrHeader .dropdown-menu {
  background-color: rgb(20, 20, 20);
  border-radius: 0;
  /* top: 52px !important; */
  top: 79px !important;
  padding: 5px 0;
  left: -22px !important;
  width: 225px;
}

.parrHeader .dropdown-menu .nav-link.nav-item {
  background-color: initial !important;
  padding: 5px 0;
  margin: 0 auto 7px;
  width: fit-content;
}

.parrHeader .nav-link:hover,
.parrHeader .nav-link.active,
.parrHeader .dropdown-item:hover {
  /* color: red !important; */
  text-decoration: underline;
  text-decoration-color: red;
  text-underline-offset: 5px;
}

.parrHeader .navbar-toggler {
  padding: 0;
  border: 0;
  box-shadow: none;
  border-radius: 0;
}

.parrHeader .navbar-toggler .navbar-toggler-icon {
  filter: drop-shadow(0px 0px 0px #ffffff);
}

.nav-item:hover .dropdown-menu {
  display: block !important;
}

/* For responsive */
.parrHeader .collapse.show,
.parrHeader .collapsing {
  position: absolute;
  left: 0;
  right: 0;
  background: #151515;
  top: 80px;
  height: calc(100vh - 80px) !important;
  overflow: auto;
  z-index: 9;
  padding: 63px 0;
}

.parrHeader .rightNav .nav-link {
  margin: 0 19px;
}

/* /// Header style */

/* For footer (Mohit) */
.footerText {
  background-color: #151515;
  padding-top: 60px;
  padding-bottom: 80px;
  /* max-height: 342px;
    height: 342px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerText .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  padding: 0px;
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: rgb(253, 253, 252);
}

.footerText .list-group .list-group-item a {
  text-decoration: none;
  color: rgb(253, 253, 252);
  font-family: "DM Sans";
}

/* /// For footer (Mohit) */

/* for cookiesModal (Mohit) */
.cookiesModal .modal-title {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.4em;
  color: #696969;
  font-weight: 700;
}

button.outline-btn-danger.serveBtn.btn.btn-primary {
  color: #ffffff !important;
}

.cookiesModal .modal-title img {
  height: 40px;
  margin-right: 10px;
}

.cookiesModal .modal-body {
  padding: 0;
  display: flex;
}

.cookiesModal .modal-body .nav-item {
  width: fit-content;
  border-bottom: 1px solid #d7d7d7;
}

.cookiesModal .modal-body button.nav-link {
  color: #696969;
  font-weight: 700;
  padding: 12px 5px 12px 12px;
  font-size: 14px;
  background-color: #f4f4f4;
  width: 225px;
  word-break: break-word;
  text-align: left;
  border-bottom: 1px solid #d7d7d7;
  border-right: 0;
  border-top: 0;
  border-left: 10px solid #f4f4f4;
  border-radius: 0;
}

.cookiesModal .modal-body button.nav-link.active {
  background-color: transparent;
  border-color: #000000;
}

.cookiesModal .modal-body .tab-content {
  padding: 20px 20px 20px 30px;
}

.cookiesModal .modal-body .tab-content h6 {
  color: #696969;
  font-weight: 700;
  font-size: 14px;
}

.cookiesModal .modal-body .tab-content .desc {
  word-break: break-word;
  word-wrap: break-word;
  text-align: left;
  font-size: 0.813em;
  line-height: 1.5;
  margin: 0;
  color: #696969;
}

.cookiesModal .modal-body .choiceBtn {
  padding: 10px 30px;
  border-radius: 0;
}

.cookiesModal .modal-footer {
  height: 30px;
  width: 100%;
  text-align: right;
  background: #f4f4f4;
  border-radius: 0 0 2px 2px;
  padding: 0;
}

.cookiesModal .modal-footer a {
  text-decoration: none;
  margin: 0 10px;
}

.cookiesModal .modal-footer a img {
  height: auto;
  width: 152px;
}

/* /// for cookiesModal (Mohit) */

/* for story page */
.story-container {
  background-image: url(../public/img/paper-texture.jpg);
  background-size: cover;
  overflow: hidden;
  font-family: "Basic Commercial";
}

.h2-heading {
  margin-bottom: 0px;
  font-weight: normal !important;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
  color: black;
}

h2.h2-heading {
  font-size: 70px;
  padding-top: 83px;
  margin-bottom: 0px;
}

h3.h2-heading {
  font-size: 53px;
  margin-bottom: 22px;
}

.h2-heading span,
.h3-heading span,
.colorRed {
  color: rgb(216, 62, 53);
}

.h3-heading {
  text-align: center;
  margin-bottom: 16px;
  font-weight: normal;
  font-size: 40px;
  line-height: 115%;
  letter-spacing: -0.02em;
  color: black;
}

.h3-heading-hr {
  border: 2px solid rgb(216, 62, 53);
  max-width: 700px;
  margin: auto;
}

.story-container .mt120 {
  margin-top: 120px;
}

.story-container .mt70 {
  margin-top: 70px;
}

.story-image1 {
  display: block;
  width: 527px;
}

.storyContainer1 {
  margin-bottom: 34px;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  padding-left: 110px;
}

.storyContainer1.fs25 {
  font-size: 25px;
}

.drivenDiv .fs30 {
  font-size: 30px;
  color: #000000;
}

.storyContainer2 {
  padding-top: 20px;
  margin-bottom: 17px;
  font-size: 25px;
  line-height: 140%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.story-image2 {
  width: 424px;
  margin-left: 100px;
}

.storyContainer3 {
  font-size: 25px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.story-image3 {
  width: 409px;
}

.story-image4 {
  width: 250px;
  position: relative;
  top: -40px;
  left: 348px;
}

.drivenDiv {
  text-align: center;
  max-width: 570px;
  margin: auto;
  width: 100%;
}

.drivenDiv span {
  color: #d83e35;
}

.drivenDiv p {
  padding-top: 25px;
  margin-bottom: 14px;
  font-size: 25px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #212529;
}

.story-image5 {
  width: 540px;
}

.heading_middle_text {
  color: #d83e35;
  text-align: center;
  padding: 56px 0 27px;
  margin: 0;
  font-weight: normal !important;
}

.golden-line {
  max-width: 436px;
  margin: auto;
  border: 1px solid rgb(166, 131, 69);
  margin-top: 40px;
  height: 3px;
}

.srtoryrow6 {
  padding-top: 21px;
  font-size: 25px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.red-color {
  color: rgb(216, 62, 53) !important;
}

.red-letter {
  color: rgb(216, 62, 53);
  font-size: 70px;
}

.h2span-text {
  font-size: 70px;
  color: black;
  font-weight: normal !important;
}

.srtoryrow6text {
  margin: auto;
  -webkit-box-pack: center;
  justify-content: center;
}

.srtoryrow6text img {
  max-width: 260px;
  display: inline;
}

.oldparrBotel {
  width: 150px;
  padding-top: 20px;
}

/* /// for story page */

/* --- Where to by page css */
.page-where-to-buy {
  background-color: rgb(227, 218, 206);
  padding: 75px 0;
}

.where-to-buy-Headline h1 {
  font-weight: bold;
  font-size: 58px;
  margin-bottom: 5px;
}

.page-where-to-buy .inner {
  text-align: center;
  max-width: 370px;
  margin: auto;
}

.whereDiv {
  display: flex;
  flex-direction: row;
}

.whereDiv .find-section,
.whereDiv .shop-online {
  width: 50%;
}

.page-where-to-buy .heading h2 {
  font-weight: 700;
  font-size: 29px;
  margin-bottom: 20px;
}

.page-where-to-buy .icon {
  font-size: 80px;
  color: #000000;
  margin-bottom: 15px;
}

.page-where-to-buy .copy p {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.1em;
  margin-bottom: 15px;
  font-family: Mark W05 Bold, sans-serif;
  color: #000000;
}

.page-where-to-buy .mapboxgl-ctrl-geocoder {
  width: 100%;
  max-width: 100%;
  border: 0.75px solid #151515;
  background-color: transparent;
  border-radius: 30px;
  margin: 30px 0 15px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-where-to-buy .mapboxgl-ctrl-geocoder--icon {
  top: 13px;
  fill: #000000;
  width: 20px;
  height: 20px;
}

.page-where-to-buy .mapboxgl-ctrl-geocoder input {
  color: #000000;
  font-weight: 700;
  margin-bottom: 0;
  font-size: 13px;
}

.page-where-to-buy .mapboxgl-ctrl-geocoder--button {
  background-color: transparent;
  top: 11px;
  width: 20px;
  height: 20px;
}

ul.suggestions {
  text-align: start;
}

ul.suggestions li {
  text-transform: uppercase;
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--powered-by {
  display: none !important;
}

.page-where-to-buy .mapboxgl-ctrl-geocoder input::placeholder {
  color: #000000;
}

.page-where-to-buy .red_btn {
  background-color: #d83e35 !important;
  border-color: #d83e35 !important;
  border-radius: 30px;
  max-width: 100%;
  width: 100%;
  outline: none;
  color: #fff;
  font-family: "Basic Commercial LT W05 Roman";
  font-style: normal;
  font-weight: 700;
  font-size: 13.5px;
  height: 45px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
}

/* --- end Where to by page css */

/* start the serve page css start */
/* slic slider css start */
.serve-container .bg_img_div {
  background-image: url(../public/img/Cream-Crackle.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

section.story-container p {
  font-style: italic;
}

.serve-container .learn-more-btn {
  background-color: rgb(216, 62, 53) !important;
  max-width: 200px;
  width: 100%;
  border-radius: 30px;
  border: 3px solid rgb(216, 62, 53) !important;
  height: 50px;
  font-family: "Basic Commercial LT W05 Roman";
  font-weight: 600;
  font-style: italic;
}

.serve-container .head-style {
  max-width: 400px;
}

.serve-container .head-style h2 {
  font-family: "Conduit ITC W05 Regular";
  font-style: italic;
  font-weight: bold;
  font-size: 44px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.serve-container .head-style p {
  font-family: "Basic Commercial LT W05 Roman", Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-style: italic;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: rgb(21, 21, 21);
  opacity: 0.5;
  margin: 20px 0 30px;
}

.container-slider {
  margin: 0 auto;
  padding: 55px 0 30px;
  color: #333;
  background: #dfd5c5;
  position: relative;
  z-index: 9999;
}

.slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}

.slick-slide h4 {
  color: rgb(21, 21, 21);
  font-weight: 700;
  font-size: 27px;
  line-height: 120%;
  margin-top: 15px;
  text-align: start;
}

.slick-thumb li img {
  filter: grayscale(100%);
}

.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}

.slick-vertical .slick-slide {
  height: 180px;
}

.slick-arrow {
  background-color: #000000 !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  background-image: url("../public/img/yellow-arrow.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.slick-prev {
  left: 10px !important;
  transform: rotate(180deg) !important;
  top: 37% !important;
}

.slick-next {
  right: 10px !important;
  top: 46% !important;
}

.slick-prev:before,
.slick-next:before,
.dropdown-toggle:after {
  display: none !important;
}

.slick-slide:first div {
  text-align: center;
}

/* slic slider css start */
/* --- end the serve to by page css */

/* newsLetter-container (Mohit) */
.newsLetter-container,
.serve-child-conitainer {
  background-color: #e3dace;
}

.newsLetter .stayTouch {
  color: #000000;
}

.newsLetter .stayTouch h1 {
  margin: 0px;
  text-align: center;
  font-size: 50px;
  font-weight: 500 !important;
  color: #000000;
  text-transform: capitalize;
  margin-top: 20px;
}

.newsLetter .stayTouch p {
  font-family: "Basic Commercial LT W05 Roman";
  text-align: center;
  font-weight: 500;
  color: #000000;
  font-size: 15px;
  margin-bottom: 25px;
  font-style: normal;
}

.newsLetter .touchForm .form-control,
.newsLetter .touchForm select {
  box-shadow: rgb(0 0 0 / 9%) 0px 0px 20px 0px;
  width: 100%;
  padding: 7px 12px;
  font-size: 16px;
  background-color: #ffffff;
  color: #000000;
  text-transform: none;
  appearance: none;
  border-radius: 30px;
  border-width: 0px;
  font-family: "Basic Commercial LT W05 Roman";
  font-weight: 500;
}

.newsLetter .touchForm .form-control:focus,
select:focus {
  outline: 2px solid !important;
}

.newsLetter .touchForm .form-control::placeholder {
  color: #000000;
}

.newsLetter .touchForm .form-check {
  margin-left: 10px;
  display: inline-block;
  line-height: 1.2;
  font-size: 12px;
  min-height: 0;
  margin-bottom: 0px;
  font-family: "Basic Commercial LT W05 Roman";
}

.newsLetter .touchForm .form-check input {
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px 0px;
  cursor: pointer;
  border: 1.5px solid rgb(0 0 0 / 54%);
}

.newsLetter .touchForm label {
  cursor: pointer;
}

.newsLetter .touchForm .btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.newsLetter .touchForm button {
  max-width: 300px;
  width: 100%;
  border-radius: 30px;
  height: 60px;
  text-transform: uppercase;
}

.newsLetter .touchForm .smallText {
  font-family: "Basic Commercial LT W05 Roman";
  font-size: 10px;
  margin-bottom: 20px;
}

.newsLetter .required_text {
  font-family: "Basic Commercial LT W05 Roman";
  font-size: 13px;
  line-height: 0;
  color: red;
  margin-left: 20px;
}

.modal-header .closeBtn {
  padding: 0 !important;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
  color: #000000 !important;
  font-family: sans-serif;
  border: 0 !important;
  background-color: transparent !important;
}

.newsLetter .modal-content {
  background-color: #e3dace;
  border-radius: 16px;
  border: 2px solid #000000;
}

/* .newsLetter input[type=date]:focus::before, .newsLetter input[type=date] {
    color: transparent;
} */
.newsLetter input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: var(--boxAfterBackColor, #212529);
}

.newsLetter input[type="date"]:focus {
  color: #212529 !important;
}

/* /// newsLetter-container (Mohit) */

/* serve-child-conitainer (Mohit) */
.serve-child-conitainer .head-style {
  text-align: center;
  margin-bottom: 100px;
}

.serve-child-conitainer .head-style h1,
.imgAndDesc h1 {
  color: rgb(21, 21, 21);
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  letter-spacing: -0.02em;
  width: 100%;
}

.serve-child-conitainer .head-style h1::first-letter,
.imgAndDesc h1::first-letter {
  color: #d83e35;
}

.serve-child-conitainer .head-style p,
.imgAndDesc p {
  font-family: "Basic Commercial LT W05 Roman", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: rgb(21, 21, 21);
  opacity: 0.75;
}

/* .serve-child-conitainer .head-style p {
  margin-bottom: 225px;
} */

.serve-child-conitainer .child-bg {
  background-color: #e3dace;
  padding-bottom: 256px;
}

.serve-child-conitainer .img-div div h1 {
  font-weight: bold;
  font-size: 43px;
  letter-spacing: -0.02em;
  font-family: 700;
}

.serve-child-conitainer .img-div .firstDiv li {
  /* list-style-image: url('../public/img/dash.png'); */
  list-style-type: none;  
}
.firstDiv img.dash-img {
  height: auto!important;
}
li.dash-img-display {
  display: flex;
}
img.dash-img {
  margin-right: 5px;
}
.serve-child-conitainer .img-div {
  position: relative;
}
.child-bg.container .img-div img{
  height: 557px;
}

.serve-child-conitainer .img-div .img-desc {
  margin: auto;
  display: flex;
  max-height: 379px;
  height: 100%;
  max-width: 802px;
  width: 84%;
  z-index: 100;
  position: absolute;
  background: url(../public/img/Cream-Crackle.png) center center / cover repeat;
  justify-content: center;
  padding: 40px 50px 0;
  top: 80%;
  /* top: 324px; */
  left: 0;
  right: 0;
  gap:15px;
}

.serve-child-conitainer .img-div .img-desc div {
  max-width: 350px;
  width: 100%;
}

.serve-child-conitainer .img-div .img-desc ul {
  /* list-style-type: none; */
  padding: 0;
  margin: 0;
}

.serve-child-conitainer .img-div .img-desc li {
  font-family: "Basic Commercial LT W05 Roman";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: rgb(21, 21, 21);
  opacity: 0.75;
}

/* .serve-child-conitainer .img-div .border-div {
  border-image: url(../public/img/Cream-Crackle.png) 25 / 7px / 0px repeat;
  margin: 0 auto -443px auto;
  height: 324px;
  max-width: 802px;
  max-width: 84%;
  z-index: 100;
  position: relative;
  border-width: 50px;
  border-style: solid;
  border-color: transparent;
} */
.serve-child-conitainer .img-div .border-div {
  border-image: url(../public/img/Cream-Crackle.png) 25 / 7px / 0px repeat;
  height: 100%;
  max-width: 802px;
  /* z-index: 100; */
  position: absolute;
  border-width: 50px;
  border-style: solid;
  border-color: transparent;
  width: 84%;
  left: 0;
  right: 0;
  margin: auto;
  top: 21%;
}

.buttons button.bg-btn-danger {
  background-color: #d83e35 !important;
  color: #ffffff !important;
  margin-left: 25px;
}

/* /// serve-child-conitainer (Mohit) */

/* For whiskies page*/
.bottel-container {
  background-color: #dfd5c5;
}

.bottel-container .bottel-bg img {
  width: 100%;
  height: 360px;
}

.bottel-container .bottel-img img.mobileImg {
  display: none;
}

.bottel-container .bottel-img img.deksTopImg {
  position: absolute;
  z-index: 1;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 9px 81px);
  top: 113px;
  /* left: 305px; */
  right: 50%;
}

.bottel-container .brandAge {
  display: flex;
  align-items: center;
  height: 375px;
}

/* .brand-img-div {
  max-width: 100%;
  width: 100%;
} */
.brand-img-div .age-img {
  max-width: 320px;
  width: 100%;
  text-align: center;
}

.brand-img-div h1 {
  font-size: 49px;
  margin-bottom: 20px;
}

.brand-img-div h3 {
  font-size: 28px;
}

.brand-img-div .age-img button.btn {
  border-radius: 30px;
  height: 45px;
  line-height: 1.7rem;
  margin: 35px auto 0;
  max-width: 260px;
  text-transform: uppercase;
  width: 100%;
  font-style: italic;
}

.bottel-container .imgAndDesc {
  width: 100%;
  margin: 0 auto;
  padding: 150px 12px 50px;
}

.imgAndDesc .gridDiv {
  display: flex;
}

.imgAndDesc .gridDiv .desc_img img {
  width: 530px;
}

.descMainDiv.eighteenyr{
  margin-bottom: 20px;

}
.imgAndDesc h1 {
  font-size: 48px !important;
  text-align: left !important;
  color: #212529;
  max-width: 350px;
  width: 100%;
  line-height: 48px;;
}

.imgAndDesc p {
  font-size: 16px;
  color: #212529;
  line-height: 20px;
  opacity: 1;
  letter-spacing: inherit;
  font-style: italic;
}

.imgAndDesc .wbottle img {
  max-width: 500px;
  width: 100%;
}

.imgAndDesc .borderCOl {
  width: 2px;
  border-left: 2px solid #d83e35;
}

.imgAndDesc .headingText p,
.imgAndDesc .descText p {
  font-size: 16.5px;
  margin-bottom: 10px;
}

.imgAndDesc .descText p {
  color: #151515;
  opacity: 0.5;
}

.buttons button {
  display: inline-block;
  font-size: 12.5px;
  line-height: 1.7rem;
  text-transform: uppercase;
  border-radius: 30px;
  margin: 0px auto;
  width: 156px;
  height: 35px;
  padding: 0;
  border-width: 2px;
  background-color: transparent !important;
  color: #000000 !important;
  font-style: italic;
}

.buttons button.bg-btn-danger {
  color: #ffffff !important;
  margin-left: 25px;
}

/* /// end whiskies page */

/* For home page */
.home-conitainer .firsct-conitainer {
  background: url(../public/img/Cream-Crackle-mixed-rotate.png);
  background-size: cover;
}

/* .home-conitainer .firsct-conitainer {
  background: url(../public/img/Cream-Crackle-mixed.png),100% repeat;
  background-size: 69%;
  background-position: right;
} */

.home-conitainer .imgText-bg {
  background-image: url(../public/img/home/op-firstbg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover;
  width: 100%;
}

.home-conitainer .imgText-bg .descText {
  max-width: 575px;
  margin-top: 50px;
}

.home-conitainer .imgText-bg h1 {
  color: #151515;
  margin-bottom: 0px;
  font-size: 64px;
  text-align: left;
  text-shadow: rgb(0 0 0) 0px 1px;
  letter-spacing: -0.02em;
}

.home-conitainer .imgText-bg p {
  font-size: 26px;
  padding-top: 24px;
  font-family: "Basic Commercial LT W05 Roman";
  font-style: normal;
  line-height: 34px;
  letter-spacing: -0.03em;
  color: #151515;
  opacity: 0.75;
  position: relative;
  z-index: 3;
}

.home-conitainer button.btn {
  font-style: italic;
  font-size: 18px;
  line-height: 17px;
  border-radius: 30px;
  padding: 12.6px 38px;
  margin-top: 35px;
  text-transform: uppercase;
  position: relative;
  z-index: 9;
}

.home-conitainer .second-conitainer,
.home-conitainer .second-conitainer .bgImage {
  background-color: #311712;
  height: 830px;
  max-height: 100%;
}

.home-conitainer .second-conitainer .bgImage {
  background: url(../public/img/Cream-Crackle-mixed.png) center center / cover repeat;
  clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 87%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-conitainer .second-conitainer .inspiredImg {
  display: block;
  border-radius: 50%;
}

.home-conitainer .second-conitainer{
  position: relative;
}
.second-conitainer .bgImage{
  position: absolute;
  width: 100%;
  top: -2px;
}
.home-conitainer .second-conitainer .inspiredText h2 {
  line-height: 120%;
  letter-spacing: -0.02em;
  font-size: 41px;
  margin-bottom: 32px;
  max-width: 410px;
}

.home-conitainer .second-conitainer .inspiredText p {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.06px;
  opacity: 0.5;
  color: rgb(21, 21, 21);
  font-family: "Basic Commercial LT W05 Roman";
  font-style: normal;
}

.home-conitainer .third-conitainer {
  background: url(../public/img/Cream-Crackle-mixed.png) center center / cover repeat;
  /* height: 680px; */
  max-height: 100%;
}

.home-conitainer .third-conitainer .whiskeyCocktail-bg {
  background-color: #311712;
  height: 100%;
  /* height: 970px; */
  max-height: 100%;
}

.home-conitainer .third-conitainer h2 {
  font-size: 90px;
  color: #ffffff;
  padding-top: 60px;
}

.home-conitainer .third-conitainer .imgDescDiv {
  /* max-width: 1380px; */
  margin: 125px auto 0;
  background: url(../public/img/Cream-Crackle.png);
  padding: 25px 90px 0;
  position: relative;
  z-index: 2;
  /* height: 100%; */
}

.home-conitainer .third-conitainer .imgDescDiv .head-style h2 {
  padding-top: 0;
  color: #000000;
  font-size: 58px;
  margin-bottom: 30px;
}

.home-conitainer .third-conitainer .imgDescDiv p {
  font-family: "Basic Commercial LT W05 Roman", Arial, Helvetica, sans-serif;
  font-size: 24px;
  letter-spacing: -0.72px;
  color: rgb(21, 21, 21);
  opacity: 0.5;
  line-height: 30px;
}

.home-conitainer .slick-slide {
  display: block !important;
}

.home-conitainer .sliderContain {
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 950px;
  width: 100%;
  justify-content: space-between;
  margin: auto;
}

.home-conitainer .sliderContain h1 {
  font-size: 56px;
  margin-bottom: 20px;
  font-weight: 700;
}

.home-conitainer .sliderContain h2 {
  text-align: center;
  font-size: 33px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #151515;
}

.home-conitainer .brandAge img {
  margin: 0 30px !important;
  width: 150px;
}

.sliderContain .brandImg img {
  width: 350px;
  position: relative;
  bottom: -12px;
}

.fourth-conitainer {
  background: url(../public/img/Cream-Crackle.png) 50% / cover repeat;
  clip-path: polygon(0 16%, 100% 1%, 100% 100%, 0 100%);
  position: relative;
  top: -280px;
  margin-bottom: -280px;
}

.fourth-conitainer .blendModeDiv .slick-next {
  top: 41.5% !important;
}

.fourth-conitainer .blendModeDiv {
  background: #e3dace;
  mix-blend-mode: multiply;
  padding-top: 330px;
}

.nearYou .text-start {
  width: 20%;
}

.nearYou,
.nearYouText {
  display: flex;
}

.nearYouText {
  justify-content: space-between;
  width: 80%;
}

/* /// For home page */

/* near-you-conitainer (Mohit) */
.near-you-conitainer,
.order-conitainer {
  min-height: 200px;
  max-height: 100%;
  display: flex;
  align-items: center;
  background-color: rgb(253, 253, 252);
  z-index: 9;
  position: relative;
}

.near-you-conitainer h2,
.order-conitainer h2 {
  text-align: center;
  font-size: 30px;
  letter-spacing: -0.02em;
  color: #151515;
  margin: 0;
}

.near-you-conitainer .zipCode input,
.near-you-conitainer select.form-select {
  width: 150px;
  height: 45px;
  border: 0.75px solid rgb(21, 21, 21);
  border-radius: 30px;
  box-shadow: none;
  font-family: "Basic Commercial LT W05 Roman";
  font-style: normal;
  font-weight: bold;
  font-size: 13.5px;
  outline: none;
  color: rgb(21, 21, 21);
  line-height: 120%;
  text-transform: uppercase;
  background: url("../public/img/location.png") 95% center no-repeat scroll;
  background-size: 14px !important;
}

.near-you-conitainer select.form-select {
  width: 300px;
  background: url("../public/img/dropdown-icon.png") 97% center no-repeat scroll;
  background-size: 16px !important;
  font-size: 18px;
  font-weight: 700;
}

.near-you-conitainer button.btn,
.order-conitainer button.btn {
  margin-top: 0;
  width: 200px;
  padding: 0;
  font-size: 13.5px;
  font-style: normal;
  text-transform: uppercase;
  height: 45px;
}

.near-you {
  margin-top: 0;
  width: 200px;
  padding: 0;
  font-size: 13.5px;
  font-style: normal;
  text-transform: uppercase;
}

.home-conitainer .imgText-bg img.img-fluid.m {
  display: none;
}

/* /// near-you-conitainer (Mohit) */

/* Order conitainer (Mohit) */
.order-conitainer {
  background-color: #d83e35;
}

.order-conitainer h2 {
  color: #ffffff;
  text-align: start;
}

.order-conitainer button.btn {
  background-color: rgb(21, 21, 21) !important;
  max-width: 240px;
  width: 100%;
  height: 52px;
  color: #ffffff !important;
}

#location,
.mapboxgl-ctrl-geocoder--input {
  text-transform: uppercase;
}

/* /// Order conitainer (Mohit) */
/* error page css */
section.error-page {
  margin: 60px 0 90px;
}

section.error-page h1 {
  margin-bottom: 30px;
  font-size: 70px;
}

section.error-page h1 span {
  font-size: 200px;
}

section.error-page h2 {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 50px;
  letter-spacing: 0;
  text-align: center;
}

section.error-page p {
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.04em;
  text-align: center;
}

section.error-page p a {
  color: #e94634;
  text-decoration: none;
}

.backBtn {
  background: transparent;
  border: 0;
  position: relative;
  left: 0;
  text-align: start;
  padding-bottom: 10px;
}

.newsLetter input[type=date]::before {
  content: attr(placeholder);
  position: absolute;
  color: var(--boxAfterBackColor, #5c6268);
}

.newsLetter input[type=date]:focus {
  color: #212529 !important;
}

.newsLetter .input,
.newsLetter input[type=date] {
  position: relative;
}

.newsLetter input[type=date]:before {
  content: attr(placeholder);
  position: absolute;
  left: 1px;
  right: 33px;
  top: -3px;
  bottom: 1px;
  padding: 10px;
  color: #000000;
  background-color: #ffffff;
}

.newsLetter input[type=date]:focus::before {
  content: none;
}

.newsLetter .rem-placeholder::before {
  content: none !important;
  display: none !important;
}

.error input[type=checkbox] {
  outline: 1px solid #c93c2b;
  border-color: #c93c2b;
  box-shadow: 0 0 4px #c93c2b !important;
}


/* For footer */
.footerLogo img {
  margin-top: 63px;
}

#footer #dg_footer_wrapper {
  background-color: #151515;
}

@media screen and (max-width: 991px) {
  footer .footerLogo {
    margin-top: 33px;
    margin-bottom: 41px;
  }

  .hidden_mobile {
    display: none;
  }

  .hidden_desktop {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .hidden_phone {
    display: none;
  }
}





/* added by custom */
#footer #dg_footer_wrapper_inner ul {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li {
  width: 50%;
    display: inline-flex;
}
#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_last{
  width: 100% !important;
    display: inline-flex;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li a, #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li span{
padding: 0;
  margin: 0;
  font-family: 'Basic Commercial LT W05 Roman';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #fdfdfc; 
}
#dg_footer_wrapper_inner ul li:after {
  display: none !important;
}

@media (max-width: 769px) {
    #footer #dg_footer_wrapper_inner ul {
      display: block;
    }
    #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li {
      width: 100%;
        display: block;
    }
    .footerLogo{
      text-align: center;
  } 
}

@media (max-width: 769px) {

}


/* end added by custom */

/* #footer #dg_footer_wrapper #dg_footer_wrapper_inner {
  text-align: left;
  width: 100%;
} */


/* #footer #dg_footer_wrapper #dg_footer_wrapper_inner .dg_footer_row_0 {
  padding: 0;
  margin: 0;
  font-family: 'Basic Commercial LT W05 Roman';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-transform: uppercase;

  color: #fdfdfc;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner .dg_footer_row_0 a {
  color: white;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner .dg_footer_row_0 a:hover {
  text-decoration: none;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li {
  display: flex;
  width: 33.33%;
}

@media (max-width: 767px) {
  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li {
    width: 30%;
  }
}

@media (max-width: 479px) {
  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li {
    width: 100%;
    justify-content: center;
  }
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li:after {
  content: '';
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li a {
  padding: 0;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li span {
  color: white;
  padding: 0 !important;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_0 {
  align-self: flex-end;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_4 {
  align-self: flex-end;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_1 {
  width: 33.33%;
  align-self: flex-end;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_3 {
  order: 8;
  width: 60%;
  color: #fdfdfc;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_5 {
  width: 60%;
}

@media (max-width: 767px) {

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_1,
  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_3,
  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_5 {
    width: 50%;
  }
}

@media (max-width: 479px) {

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_1,
  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_3,
  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_5 {
    width: 100%;
  }
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_7 {
  width: 33.33%;
  order: 8;
}

@media (max-width: 479px) {
  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_7 span {
    width: 100%;
  }
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_7 span {
  padding: 0;
  display: inline-block;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_8 {
  order: 10;
  width: 33.33%;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_8#dg_THE_BAR {
  font-size: 12px;
  font-style: normal;
  line-height: 33px;
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_last {
  order: 3;
  color: white;
  top: 55px;
  left: 0;
  margin: 0;
  border: none;
  width: 15;
  height: 100%;
  position: relative;
} */


  /* @media (min-width: 1402px) {
    #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_last {
      width: 30%;
    }
    } */

/* @media (max-width: 1675px) {
  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_last {
    width: 33.33%;
  }
}

#footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_last span {
  padding: 0px;
}

.dg_footer_item_last {
  width: 30%;
  order: 3;
}

.dg_footer_item_8 {
  width: 30%;
}

.dg_footer_item_7 {
  width: 30%;
}

.dg_footer_item_5 {
  width: 30%;
}

.dg_footer_item_0 {
  order: 1;
}

.dg_footer_item_1 {
  order: 4;
  width: 30%;
}

.dg_footer_item_4 {
  order: 2;
  align-self: end;
}

.dg_footer_item_5 {
  order: 5;
  width: 60%;
}

.dg_footer_item_8 {
  order: 10;
}

.dg_footer_item_2 {
  order: 7;
}

.dg_footer_item_6 {
  order: 8;
  width: 60%;
}

.dg_footer_item_3 {
  order: 9;
  width: 30%;
}

.dg_footer_item_7 {
  order: 10;
}

@media (max-width: 991px) {
  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li {
    justify-content: left;
    margin-top: 13px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul span {
    margin-top: 32px;
    justify-content: left;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    text-align: left;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_3 {
    order: 7;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: rgb(253, 253, 252);
    order: 7;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  #footer img {
    margin-top: 33px;
    text-align: left;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul {
    display: flex;
    flex-flow: column;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_8 {
    order: 11;
    width: 100%;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_last {
    top: 0px;
    width: 100%;
    order: 10;
    color: white;
    left: 0px;
    margin: 0px;
    border: medium none;
    height: 100%;
    position: relative;
  }

  .dg_footer_item_last {
    order: 10;
    width: 100%;
  }

  .dg_footer_item_8 {
    order: 11;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_0 {
    align-self: flex-start;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_0 {
    align-self: flex-start;
  }

  .dg_footer_item_1 {
    align-self: flex-start;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li {
    width: 100%;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_1 {
    width: 100%;
  }

  #footer #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_7 {
    width: 100%;
  }

  #dg_footer_wrapper #dg_footer_wrapper_inner ul li.dg_footer_item_3 {
    width: 100%;
  }

  #footer,
  #dg_footer_wrapper,
  #dg_footer_wrapper_inner,
  ul li.dg_footer_item_last {
    width: 100%;
  }
} */


@media only screen and (max-width: 1072px) and (min-width: 991px)  {
  .parrHeader .nav-link, .parrHeader .dropdown-item  {
    margin: 0px 6px;
}
}




/* new css add for story page */
.dFlex {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.firstChild,
.secondChild,
.thirdChild,
.forthChild {
  width: 50%;
}

.fifthChild,
.sixthChlid {
  width: 100%;
}

.parrHeader p {
  display: none;
}
.imgAndDesc.container .descMainDiv h1 {
  max-width: 260px;
}

.descMainDiv p{ 
  margin-bottom: 0px;
}
/* header for dropdown */
 .dropdown-menu{
    margin: 0 !important;
  }
.dropdown.whiskies-dropdown{
  min-height: 80px;
}

.secondDiv ol{
  padding-left: 20px;
}
p.additional-text{
  text-align: center;
  color: #212529;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  font-weight: 400;
  margin-left: 6px;
}
.percent-text.mt-lg-5.mt-4{
  margin-top: 1.5rem !important;
}
.additional-text{
  max-width: 325px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-left: 6px;

}
/* css updated on 31/01/2024 */
.recapta-group div div div {
  width: 100% !important;
  text-align: center;
  height: 80px !important;
}
.multselect-dropdown .css-13cymwt-control , .css-t3ipsp-control:hover , .css-t3ipsp-control:focus-within{
  box-shadow: rgb(0 0 0 / 9%) 0px 0px 20px 0px;
  width: 100%;
  padding: 0px 12px;
  font-size: 16px;
  background-color: #ffffff;
  color: #000000;
  text-transform: none;
  appearance: none;
  border-radius: 30px;
  border-width: 0px;
  font-family: "Basic Commercial LT W05 Roman";
  font-weight: 500;
}
.css-1jqq78o-placeholder, .css-tj5bde-Svg{
  color: #000000 !important;
}
.css-1u9des2-indicatorSeparator{
  width:0px !important;
}
.css-1fdsijx-ValueContainer {
  padding-left: 0 !important;
}
span.required_text {
  margin-left: 20px !important;
}
/* #salesforceForm .form-select {
  margin-bottom: 0 !important;
} */
#salesforceForm .mb-3.input span {
  margin-left: 20px !important;
  color: red !important;
  font-family: Basic Commercial LT W05 Roman;
  font-size: 13px;
  line-height: unset !important;
}
#salesforceForm .input span{
  color: red;
  font-size: 13px;
  display: block;
  margin-left: 20px;
}
textarea::-webkit-scrollbar {
  display: none;
}
 
.css-1xc3v61-indicatorContainer path {
  color: #00000080;
}
.touchForm select{
  margin-bottom: 6px !important;
}
.recapta-group {
  padding-top: 30px;
}
.modal-content {
  margin-top: 90px;
}
.fade.newsLetter.modal.show {
  padding-left: 0px !important;
}